/* main */
.main {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
}

/* menu */
.menu {
  width: 200px;
  background-color: #2a2b30;
  z-index: 10;
}
.menu .img{
    width: 100%;
    height: max-content;
    position: relative;
}
.menu .img img{
    width: 200px;
}
.menu .img .user-name{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 7px;
    background-color: #00a1e1b6;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    color: #fff;
}
.menu .navbar {
    width: 100%;
    height: max-content;
}
.menu .navbar .nav {
    width: 100%;
    height: max-content;
}
.menu .navbar .nav a{
    display: block;
    width: 100%;
    height: max-content;
    padding: 12px 0 12px 39px;
    color: #afacac;
    border-bottom: 0.5px solid #000;
    display: flex;
    flex-direction: row;
}
.menu .navbar .nav a span{
    margin-left: 5px;
}
.menu .navbar .nav a:hover{
    color: #fff;
}
.menu .navbar .nav a i{
    font-size: 20px;
}
.menu .links {
    width: 100%;
    justify-content: space-evenly;
    padding: 10px;
    margin-top: 20px;
}
.menu .links li a{
    color: #afacac;
    font-size: 20px;
    padding: 10px;
}
.menu .links li a:hover {
    color: #fff;
}
.menu .copy {
    width: 100%;
    margin: 10px 0;
    text-align: center;
    color: #fff;
}
.mobileStyle {
    position: relative;
    width: 100%;
    color: #fff;
}
.mobileStyle .menu-back {
    display: none;
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 5;
    background-color: #0000008f;
}
.mobileStyle .menu-icon {
    display: none;
    position: fixed;
    width: 100%;
    height: max-content;
    top: 0;
    z-index: 4;
    background-color: #000000;
    color: #fff;
}


.pageBody {
    width: 100%;
    height: 100%;
    position: relative;
}

/* home */
.home {
    width: 100%;
    height: 100%;
    position: relative;
    background-image: url('./homeBg.jpg');
    background-size: cover;
    background-position: center;
}
.home .content{
    width: max-content;
    height: max-content;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.home .name {
    color: #fff;
}
.home .name h2{
    font-size: 60px;
}
.home .name h2 span{
    color: rgb(109 109 255);
}
.home .title {
    text-align: center;
}
.home .title span{
    font-size: 40px;
    color: #fff;
    text-align: center;
}


/* about */
.about {
    width: 100%;
    height: 100%;
    position: relative;
    padding-top: 30px;
    overflow: hidden scroll;
}
.about .constainer {
    width: 90%;
    height: 100%;
    margin: 0 auto;
}
.about .header {
    margin: 10xp 0;
}
.about h3,  .about .header i{
    font-size: 35px;
    color: #00A3E1;
}
.about .about-info {
    margin-top: 20px;
    margin-bottom: 10px;
}
.about .about-me {
    width: 60%;
}
.about .about-me h2 , .about .info h3{
    margin: 10px 0;
}
.about .about-me p {
    margin: 5px 0;
}
.about .info {
    width: 35%;
}
.about .info ul li{
    padding: 7px 0;
}
.about .info ul li b{
    border-bottom: 3px solid #00A3E1;
}
.about .info button{
    margin: 10px 0;
}
.about .servTitle {
    width: 100%;
    margin: 10px;
    text-align: center;
}
.about .services li {
    padding: 20px 0;
}
.about .services li {
    width: 30%;
    margin: 10px;
    text-align: center;
}
.about .services i{
    font-size: 60px;
    color: #00A3E1;
    margin: 0 auto;
}
.about .services h4{
    font-size: 25px;
    margin: 10px 0;
}


/* resume */
.resume {
    width: 100%;
    height: 100%;
    position: relative;
    padding-top: 30px;
    overflow: hidden scroll;
}
.resume .container {
    width: 90%;
    height: 100%;
    margin: 0 auto;
}
.resume .resumeTitle {
    margin-bottom: 10px;
}
.resume .resumeTitle h3, 
.resume .resumeTitle i {
    font-size: 35px;
    color: #00A3E1;
}
.exper {
    width: 45%;
    margin: 10px 0;
}
.exper h3, .educa h3{
    font-size: 28px;
}
.exper ul li, 
.educa ul li {
    position: relative;
    margin: 20px;
    padding: 10px 30px;
    padding-left: 50px;
    border-left: 3px solid #00A3E1;
}
.exper ul li::after, .educa ul li::after {
    content: '2021';
    position: absolute;
    top: 0;
    left: 0;
}
.exper ul li::before, .educa ul li::before{
    content: '2023';
    position: absolute;
    bottom: 0;
    left: 0; 
}
.exper ul li h4,
.educa ul li h4 {
    font-size: 22px;
}
.exper ul li span, .educa ul li span{
    font-size: 17px;
    color: #00A3E1;
}


.educa {
    width: 45%;
    margin: 10px 0;
}
.educa h3, .educa h3{
    font-size: 28px;
}
.educa ul li{
    margin: 20px 0;
    padding: 10px;
    padding-left: 50px;
    border-left: 3px solid #00A3E1;
}
.skill {
    padding-top: 20px;
    padding-bottom: 50px;
}
.skill .box {
    width: 30%;
    margin: 0 auto;
    padding: 10px;
}
.skill .box li{
    margin: 5px 0;
    border-bottom: 1px solid #5c5c5c;
}


.portfolio {
    width: 100%;
    height: 100vh;
    padding: 30px;
    position: relative;
    overflow: hidden auto;
}
.portfolio .header {
    margin: 0;
}
.portfolio h3,  .portfolio .header i{
    font-size: 35px;
    color: #00A3E1;
}
.portfolio .projects {
    width: 90%;
    height: max-content;
    padding: 20px;
    margin: 10px auto;
    border-radius: 20px;
    box-shadow: inset 0 0 3px 0;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 20px;
}
.portfolio .projects .card{
    width: 33%;
    height: max-content;
    position: relative;
    overflow: hidden;
}
.portfolio .projects .card img{
    width: 100%;
    padding: 20px;
    overflow: hidden;
}
.portfolio .projects .card .title{
    display: none;
}
.portfolio .projects .card:hover .title{
    display: block;
    position: absolute;
    width: max-content;
    height: max-content;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    padding: 10px;
    border-radius: 25px;
    text-align: center;
    color: #fff;
    font-weight: 700;
    background-color: #00A3E1;
    cursor: pointer;
}
.viewProject {
    width: 70%;
    height: max-content;
    padding: 20px;
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    box-shadow: 0 0 5px 0;
    border-radius: 20px;
    z-index: 7;
}
.viewProject .column{
    padding-top: 20px;
}
.viewProject .close{
    position: absolute;
    right: 3%;
    background-color: rgb(253, 59, 59);
    color: #fff;
}
.viewProject img{
    width: 40%;
    margin-right: 20px;
}
.viewProject h4{
    font-size: 28px;
    margin: 10px 0;
    color: #00A3E1;
}
.viewProject p {
    font-size: 15px;
    margin-bottom: 20px;
}
.viewProject .link{
    width: max-content;
    padding: 10px;
    border-radius: 10px;
    background-color: #00A3E1;
    margin: 0 10px;
    color: #fff;
    cursor: pointer;
}


.contact {
    width: 100%;
    height: 100vh;
    padding: 30px;
    position: relative;
    overflow: hidden auto;
}
.contact h3,  .contact .header i{
    font-size: 35px;
    color: #00A3E1;
}
.contact .links{
    width: 50%;
    height: max-content;
    padding: 10px;
    margin: 0 auto;
    text-align: center;
    font-size: 28px;
}
.contact .sendmsg{
    width: 90%;
    margin: 0 auto;
    height: max-content;
}
.contact .sendmsg form {
    border: none;
    box-shadow: 0 0 4px;
    border-radius: 20px;
    padding: 20px;
}
.contact .sendmsg label {
    font-size: 20px;
}
.contact .sendmsg input, 
.contact .sendmsg textarea{
    width: 100%;
    padding: 10px;
    border: 0px;
    font-size: 18px;
    border-radius: 10px;
    box-shadow: 0 0 4px 0;
    margin-bottom: 5px;
}








@media only screen and (max-width: 768px) {
    .menu {
        position: absolute;
        left: -100%;
    }
    .mobileStyle .menu-back {
        display: block;
    }
    .mobileStyle .menu-icon {
        display: block;
        padding: 10px;
        text-align: end;
    }
    .about , .resume , .portfolio, .contact{
        padding-top: 50px;
    }
}
  