.box-load {
	width: 250px;
	height: 200px;
	position: absolute;
	left: 50%;
	transform: translate(-50%, 50%);
	overflow: hidden;
	z-index: 50;
	background-color: #fff;
}

.head1, .head2 {
	width: 200px;
    height: 100px;
    background: #00f;
    border-radius: 100px 100px 0 0;
	transform-origin: 50% 100%;
	z-index: 5;
}
.head1 {
	animation: head1-animat .8s linear infinite;
	z-index: 500;
}
.head2 {
	z-index: 501;
	animation: head2-animat .8s linear infinite;
	border-radius: 0 0 100px 100px;
	transform-origin: 50% 0%;
}

@keyframes head2-animat {
	0%{
		transform: rotate(0deg);
	}
	25%{
		transform: rotate(30deg);
	}
	50%{
		transform: rotate(0deg);
	}
	75%{
		transform: rotate(30deg);
	}
	100%{
		transform: rotate(0deg);
		
	}
}
@keyframes head1-animat {
	0%{
		transform: rotate(0deg);
	}
	25%{
		transform: rotate(-30deg);
	}
	50%{
		transform: rotate(0deg);
	}
	75%{
		transform: rotate(-30deg);
	}
	100%{
		transform: rotate(0deg);
		
	}
}
.eats {
	width: max-content;
	height: max-content;
	position: absolute;
	top: 50%;
	display: flex;
	flex-direction: row;
	z-index: -4;
	animation: eat-animat 1.3s linear infinite;
}
.eats .circle {
	width: 15px;
	height: 15px;
	border-radius: 50%;
	margin: 0 25px;
	background-color: red;
	z-index: -4;
}
@keyframes eat-animat {
	0% {
		transform: translate(100px, -50%);		
	}
	100% {
		transform: translate(-95px, -50%);		
	}
}

h3.loadText {
	margin-top: 123px;
    text-align: center;
    font-size: 28px;
    font-family: sans-serif;
    display: flex;
    flex-direction: row;
    text-align: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}
.itemload {
    position: relative;
}
.itemload .loadText {
	margin: 0;
    text-align: center;
    font-size: 20px;
    font-family: sans-serif;
    display: flex;
    flex-direction: row;
    text-align: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}
.loadText .dot:nth-child(1){
    animation: dot1 1.2s linear 0.8s infinite;
}
.loadText .dot:nth-child(2){
    animation: dot1 1.2s linear 0.4s infinite;
}
.loadText .dot:nth-child(3){
    animation: dot1 1.2s linear  infinite;
}
@keyframes dot1 {
    0%{
        opacity: 1;
    }
    25%{
        opacity: 0;
    }
    50%{
        opacity: 1;
    }
    75%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}