body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.container {
  margin: 0 auto;
}


ul {
  list-style: none;
  text-decoration: none;
}

.row {
  display: flex;
  flex-direction: row;
}

.column {
  display: flex;
  flex-direction: column;
}

.between {
  justify-content: space-between;
}

.around {
  justify-content: space-around;
}

.end {
  justify-content: end;
}

.btn {
  padding: 5px;
  border: 1px solid transparent;
  background-color: transparent;
}

.btn:hover {
  border: 1px solid #000;
  box-shadow: 0 0 2px 0;
}

.t-center {
  text-align: center;
}

ul {
  list-style: none;
  padding: 0;
}

a {
  text-decoration: none;
}

button {
  background-color: #1e90ff;
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

button:hover {
  background-color: #0077b6;
}


/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #00A3E1; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #037dad; 
}